import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';
import { InputLabel, Box, Typography } from '@material-ui/core';
import SearchableSelect from 'components/SearchableSelect/SearchableSelect';

const useLabelStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const CustomSearchableSelect = ({
  afterChange, // onChange callback (not used with customOnChange)
  customOnChange,
  customStyles = { container: undefined, label: undefined },
  disabled,
  getOptionLabel,
  getOptionSelected,
  helperText,
  label,
  name,
  options,
  placeholder,
  required,
  valueOverride,
  ...props
}) => {
  const classes = useStyles();
  const labelsClasses = useLabelStyles();
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const handleChange = (event, option) => {
    helpers.setValue(option?.value);
    if (afterChange) afterChange(option?.value);
  };

  const defaultGetOptionLabel = option => option.name;
  const defaultGetOptionSelected = (option, value) => option.value === value?.value;
  return (
    <div className={cx('custom-searchable-select', customStyles.container || 'w-100')}>
      {label && (
        <Box className="" style={{ marginBottom: '14px' }}>
          <InputLabel
            variant="standard"
            disableAnimation
            shrink={false}
            className={cx(labelsClasses.default, customStyles.label || '')}
            error={hasError}
            required={required}
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          {helperText && <Typography className={classes.helperText}> {helperText}</Typography>}
        </Box>
      )}

      <SearchableSelect
        {...field}
        name={field.name}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={field.onBlur}
        onChange={customOnChange ?? handleChange}
        required={required}
        options={options}
        id={field.name}
        value={valueOverride || field.value}
        getOptionLabel={getOptionLabel || defaultGetOptionLabel}
        getOptionSelected={getOptionSelected || defaultGetOptionSelected}
        hasError={hasError}
        {...props}
      />
      {hasError ? (
        <Box component="span" color="error.main" style={{ fontWeight: '400' }}>
          {error}
        </Box>
      ) : null}
    </div>
  );
};

export default CustomSearchableSelect;
