import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { find, size, filter, includes } from 'lodash';

// library components
import { Formik, Form } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//validation library
import * as Yup from 'yup';

// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextArea from 'components/CustomFormComponents/TextArea';

//actions & selectors
import { updateAssigneeInJob } from 'redux/actions/staffingActions';
import { selectSignedInContractor } from 'redux/selectors/staffing/getContractor';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { getFirebase } from 'react-redux-firebase';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  columnTitle: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '25px',
    letterSpacing: '0.15px',
    marginBottom: '0px',
    color: '#5E5E5E',
  },
  columnContentText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 0,
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
  },
  acceptHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    fontSize: '28px',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  contentText: {
    fontSize: '18px',
    maxWidth: 360,
    marginBottom: theme.spacing(6),
  },
  closeButton: {
    textTransform: 'uppercase',
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '16px !important',
  },
}));

/**
 * This is the form for accepting or rejecting a project role
 * that is Contractor facing
 */
const ProjectRoleActionForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fbFunctions = getFirebase().functions();

  const { jobToUpdateId, projectId, projectRoleActionDialogType: action } = useStaffingSelector();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saved, setSaved] = useState(false);

  const getContractor = selectSignedInContractor();
  const contractor = useSelector(state => getContractor(state));

  //Form Initial Values
  const formInitialValues = {
    notes: '',
  };

  // Yup schema
  const formSchema = Yup.object().shape({
    notes: Yup.string(),
  });

  const handle_Accept_Reject_Project = values => {
    setIsSubmitting(true);

    const isContractorEmployee = contractor?.staffType === 'Employee';
    const acceptedStatus = isContractorEmployee ? 'signed' : 'accepted';

    let status = 'pending';
    status = action === 'accept' ? acceptedStatus : status;
    status = action === 'reject' ? 'rejected' : status;

    //Check if the Job still have available seats (Number of Contractors who have accepted the Jobs)...
    if (
      status === 'accepted' &&
      size(filter(selectedJob.assignees, m => includes(['accepted', 'signed'], m.status))) >=
        selectedJob.seats
    ) {
      dispatch(enqueueErrorSnackbar('All seats for the Selected Job are filled'));
      setIsSubmitting(false);
      return;
    }

    dispatch(
      updateAssigneeInJob(
        projectId,
        jobToUpdateId,
        contractor.id,
        {
          status,
          notes: values.notes,
        },
        () => {
          setIsSubmitting(false);
          setSaved(true);

          if (status === 'accepted') {
            try {
              fbFunctions.httpsCallable('staffing-onCall_sendJobAcceptedEmail')({
                projectId,
                jobId: jobToUpdateId,
                contractorId: contractor.id,
              });
            } catch (error) {
              console.error(error);
            }

            try {
              fbFunctions.httpsCallable('staffing-create_and_send_contract')({
                projectId,
                contractorId: contractor.id,
                jobId: jobToUpdateId,
              });
            } catch (error) {
              console.error(error);
            }
          }
        },
      ),
    );
  };

  if (!contractor) {
    return null;
  }

  const { displayName, contractorJobs, email } = contractor;

  const selectedJob = find(contractorJobs, ['jobId', jobToUpdateId]);

  if (!selectedJob) {
    return null;
  }

  const ConfirmedInfo = () => {
    return (
      <div>
        <div className={classes.contentBody}>
          {action === 'accept' ? (
            <>
              <Typography className={classes.acceptHeader}>Thanks {displayName}!</Typography>
              <Typography className={classes.contentText}>
                You’ve accepted the role. Look for an update and more details in your email shortly.
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.contentText}>
                You’ve rejected this role with additional information added. We’ll be in touch
                shortly if you need to discuss the role.
              </Typography>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            color="primary"
            variant="contained"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={async values => {
        handle_Accept_Reject_Project(values);
      }}
    >
      {({ submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          {saved ? (
            <ConfirmedInfo />
          ) : (
            <>
              <Grid container spacing={5}>
                <Grid item xs={6} className={classes.gridItem}>
                  <p className={classes.columnTitle}>Client</p>
                  <p className={classes.columnContentText}>{selectedJob.clientName}</p>
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                  <p className={classes.columnTitle}>Project Name</p>
                  <p className={classes.columnContentText}>{selectedJob.eventName}</p>
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                  <p className={classes.columnTitle}>Role</p>
                  <p className={classes.columnContentText}>{selectedJob.jobRole}</p>
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                  {(selectedJob.isLead || contractor.staffType === 'Contractor') && (
                    <>
                      <p className={classes.columnTitle}>Details</p>
                      <p className={classes.columnContentText}>
                        {selectedJob.isLead ? 'Lead' : ''}
                        {selectedJob.isLead && contractor.staffType === 'Contractor' ? ', ' : ''}
                        {contractor.staffType === 'Contractor' ? selectedJob.displayFee : ''}
                      </p>
                    </>
                  )}
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                  <p className={classes.columnTitle}>Your name</p>
                  <p className={classes.columnContentText}>{displayName}</p>
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                  <p className={classes.columnTitle}>Email</p>
                  <p className={classes.columnContentText}>{email}</p>
                </Grid>
              </Grid>

              <TextArea
                name="notes"
                type="text"
                label="Additional Notes"
                placeholder="Notes"
                multiline
                fullWidth
                rows={4}
                className="mb-4 mt-4"
              />

              <div className={classes.dialogActions}>
                <Button
                  className={classes.cancelButton}
                  disabled={isSubmitting}
                  onClick={() => {
                    closeDialog();
                    resetForm();
                  }}
                >
                  CANCEL
                </Button>
                <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                  SAVE
                  {isSubmitting && <ButtonSpinner />}
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

ProjectRoleActionForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default ProjectRoleActionForm;
