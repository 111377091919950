import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Tooltip, makeStyles } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

import { ReactComponent as StickyNoteIcon } from '../../../../assets/img/sticky_note.svg';

//actions & selectors
import {
  removeContractorFromJob,
  updateAssigneeInJob,
  sendContractReminder,
  createAndSendContract,
} from 'redux/actions/staffingActions';

// useStyles
const useStyles = makeStyles({
  controllerButton: {
    fontSize: 11,
    width: '96px',
    height: '36px',
    textTransform: 'uppercase',
    color: '#fff',
  },
  signContractBtn: {
    width: '130px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
    margin: 10,
  },
  rejectedIcon: {
    color: '#E2351E',
    marginLeft: 8,
    cursor: 'pointer',
  },
});

/**
 * This lives under /admin/staffing/contractors/{contractorId}
 */
const ContractorJobCard = ({ job }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const history = useHistory();

  // Handle Title Text Color
  const handleTitleTextColor = () => {
    switch (job.status) {
      case 'rejected':
        return 'danger';
      case 'invited':
        return 'dark';
      default:
        return 'success';
    }
  };

  const handleDetailNavigate = () => {
    history.push(`/admin/staffing/projects/detail/${job.projectId}`);
  };

  const handleContractorUnAssign = () => {
    confirm({
      description: `Are you sure you want to unassign the Staff from Job?`,
    }).then(() => {
      dispatch(removeContractorFromJob(job.projectId, job.jobId, job.contractorId));
    });
  };

  const handleContractorRemind = () => {
    confirm({
      description: `Are you sure you want to send a Reminder?`,
    }).then(() => {
      dispatch(
        updateAssigneeInJob(job.projectId, job.jobId, job.contractorId, { sentInvite: false }),
      );
    });
  };

  const handleContractorResendContract = () => {
    confirm({
      description: `Are you sure you want to send the Contract Reminder?`,
    }).then(() => {
      dispatch(sendContractReminder(job.contractorEmail, job.assigneeInfo.helloSignRequestId));
    });
  };

  const handleContractorSendContract = () => {
    confirm({
      description: `Are you sure you want to Create and Send the Contract?`,
    }).then(() => {
      dispatch(
        createAndSendContract(job.projectId, job.contractorId, job.jobId, job.contractorEmail),
      );
    });
  };

  const renderButtons = () => {
    if (!job.isActive) {
      return null;
    }
    switch (job.status) {
      case 'pending':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Invited
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cx(classes.controllerButton)}
              onClick={handleContractorRemind}
            >
              Remind
            </Button>
          </>
        );
      case 'rejected':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Rejected
            </Button>
          </>
        );
      case 'accepted':
        return (
          <>
            {job.assigneeInfo.helloSignatureRequestId && (
              <Button
                variant="outlined"
                size="small"
                color="default"
                className={cx(classes.controllerButton, classes.signContractBtn, 'bg-success')}
                onClick={handleContractorResendContract}
              >
                Resend Contract
              </Button>
            )}
            {!job.assigneeInfo.helloSignatureRequestId && (
              <Button
                variant="outlined"
                size="small"
                color="default"
                className={cx(classes.controllerButton, classes.signContractBtn, 'bg-success')}
                onClick={handleContractorSendContract}
              >
                Send Contract
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cx(classes.controllerButton)}
              onClick={handleContractorUnAssign}
            >
              Unassign
            </Button>
          </>
        );
      case 'signed':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Contracted
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cx(classes.controllerButton)}
              onClick={handleContractorUnAssign}
            >
              Unassign
            </Button>
          </>
        );
      default:
        return (
          <>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cx(classes.controllerButton)}
              onClick={handleContractorUnAssign}
            >
              Unassign
            </Button>
          </>
        );
    }
  };

  return (
    <div
      className="row shadow rounded border p-3 flex-lg-nowrap flex-sm-wrap mb-3"
      style={{ minWidth: '300px', minHeight: '200px' }}
    >
      <div
        className="d-flex flex-column col-lg w-xl-35 mr-5 mb-2"
        onClick={handleDetailNavigate}
        style={{ cursor: 'pointer' }}
      >
        <h6 className="text-uppercase text-secondary m-sm-0 mb-1">{job.clientName}</h6>
        <h3 className="m-0 font-weight-bold">{job.eventName}</h3>
        <div className="d-flex align-items-center mb-1">
          {job.isTentative && (
            <div className="bg-success rounded-pill px-1 text-white mr-1">
              <span>Tentative</span>
            </div>
          )}
          <p className="m-0">
            {job.formattedProjectStartDate} - {job.formattedProjectEndDate}
          </p>
        </div>
        <h5 className="text-wrap m-0">{job.projectDescription}</h5>
      </div>
      <div className="col-lg">
        <h6 className="text-uppercase m-0 mb-1 text-secondary">Job Details</h6>
        <div
          className="border shadow-sm rounded flex-grow-1 p-1"
          style={{ minWidth: '200px', minHeight: '140px' }}
        >
          <div className="d-flex justify-content-between border-bottom pb-1">
            <div>
              <div className="d-flex align-items-center">
                <p className={`m-0 font-weight-bold text-${handleTitleTextColor()}`}>
                  {job.jobName}, {job.jobRole}
                </p>
                {job.status === 'rejected' && (
                  <Tooltip arrow title={job.assigneeInfo.notes}>
                    <StickyNoteIcon className={classes.rejectedIcon} />
                  </Tooltip>
                )}
              </div>
              <h6 className="m-0 font-weight-normal">
                {job.seniority} {job.displayFee}
              </h6>
            </div>
            <div className={classes.buttonContainer}>{renderButtons()}</div>
          </div>
          <div className="d-flex align-items-center">
            <span className="mt-1">{job.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes
ContractorJobCard.propTypes = {
  job: PropTypes.object.isRequired,
};

export default ContractorJobCard;
