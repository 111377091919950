import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, compact, some, orderBy, get, concat, forEach, without } from 'lodash';
import { isLoaded, isEmpty } from 'react-redux-firebase';

// library components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  Chip,
  TextField,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

//actions & selectors
import {
  openManageContractorsDialog,
  addContractorToJob,
  removeContractorFromJob,
} from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { selectJob } from 'redux/selectors/staffing/getProject';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 760,
  },
  contentRoot: {
    padding: theme.spacing(5),
  },
  dialogHeader: {
    position: 'relative',
    maxWidth: 760,
    width: 760,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeaderRoot: {
    padding: '24px 8px',
  },
  dialogHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  inputPopupIcon: {
    transform: 'unset',
  },
  row: {
    margin: '15px 0',
  },
  rowTitle: {
    fontSize: '13px',
    margin: '0px 5px',
  },
  chipRoot: {
    margin: '0px 5px 8px 5px',
    borderRadius: '5px',
    background: '#EEEEEE',
    padding: 8,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 135,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  jobTitle: {
    marginBottom: theme.spacing(2), // Adds 16px of space below (adjust as needed)
  },
}));

const ManageContractorDialog = () => {
  const [value, setValue] = useState('');
  const [contractorsToAdd, setContractorsToAdd] = useState([]);

  const { isOpenManageContractorDialog } = useStaffingSelector();
  const contractors = useSelector(state => state.firestore.data.contractors);

  const confirm = useConfirm();

  const { projectToUpdateId, jobToUpdateId } = useStaffingSelector();

  const getJob = selectJob();
  const currentJob = useSelector(state => getJob(state, projectToUpdateId, jobToUpdateId));

  const dispatch = useDispatch();
  const classes = useStyles();

  //Add Active Contractors that haven't been added before as options
  const contractorOptions =
    isLoaded(contractors) && currentJob
      ? orderBy(
          compact(
            map(contractors, contractor => {
              if (
                contractor.isActive &&
                !some(currentJob.allContractors, ['contractorId', contractor.id]) &&
                !some(contractorsToAdd, ['value', contractor.id])
              ) {
                return {
                  label: `${contractor.displayName} (${contractor.email}),  ${contractor.type}`,
                  value: contractor.id,
                };
              }
            }),
          ),
          [item => get(item, 'label').toLowerCase()],
        )
      : [];

  const closeDialog = () => {
    dispatch(openManageContractorsDialog(false));
  };

  if (!currentJob) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={isOpenManageContractorDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.dialogHeader}
          classes={{ root: classes.dialogHeaderRoot }}
        >
          <Typography className={classes.dialogHeaderTitle}>Assign Staff</Typography>
          <IconButton className={classes.closeButton} aria-label="close" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.contentRoot}>
          <div>
            <Typography className={classes.jobTitle}>
              {currentJob.jobName} ({currentJob.isLead ? 'Lead' : ''} {currentJob.jobRole})
            </Typography>
            <Autocomplete
              value={value}
              options={contractorOptions}
              getOptionLabel={option => option.label}
              disableClearable
              blurOnSelect
              clearOnBlur
              popupIcon={<SearchIcon />}
              classes={{
                popupIndicatorOpen: classes.inputPopupIcon,
              }}
              onChange={(event, option) => {
                setContractorsToAdd(current => concat(current, option));
                setValue('');
              }}
              renderInput={params => <TextField {...params} label="Add" variant="filled" />}
            />
          </div>

          {!isEmpty(contractorsToAdd) && (
            <div className={classes.row}>
              <p className={classes.rowTitle}>Pending</p>
              {map(contractorsToAdd, contractor => (
                <Chip
                  label={contractor.label}
                  key={contractor.value}
                  classes={{
                    root: classes.chipRoot,
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() => {
                    setContractorsToAdd(current => without(current, contractor));
                  }}
                />
              ))}
            </div>
          )}

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setContractorsToAdd([]);
                closeDialog();
              }}
            >
              CANCEL
            </Button>
            <Button
              className={classes.saveButton}
              onClick={() => {
                forEach(contractorsToAdd, contractor => {
                  dispatch(addContractorToJob(projectToUpdateId, jobToUpdateId, contractor.value));
                });
                setContractorsToAdd([]);
                closeDialog();
              }}
            >
              SAVE
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageContractorDialog;
